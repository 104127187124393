.ser-ds {
    background-color: white;
    /* padding: 28px 16px 16px 30px; */
    padding: 16px 8px 16px 16px;
    width: 100%;
}

.ser-ib:hover {
    background: transparent !important;
}

.ser-ic {
    fill: #042315;
}

.but {
    width: 140px !important;
    height: 46px !important;
    min-width: 140px !important;
    text-transform: capitalize;
    border-radius: 0;
}

.del {
    background-color: #29707A;
    color: white;
    margin-right: 2px;
    font-size: 16px !important;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    align-items: flex-start;
    justify-content: left;
}

.del:hover {
    /* outline: 2px solid #29707A; */
    background-color: #29707A;
}

.edi {
    background-color: #4CDD84 !important;
    color: #042315 !important;
    font-size: 16px !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-weight: 500 !important;
    align-items: flex-start !important;
    justify-content: left !important;
}

.edi:hover {
    background-color: #4CDD84 !important;
    /* outline: 2px solid #4CDD84 !important; */
}

.serviceTbt .serviceHeader {
    font: normal normal normal 20px/32px 'IBM Plex Sans', sans-serif;;
    letter-spacing: 0px;
    color: #042315;
    margin-top: 0px;
    /* margin-left: 2px; */
}

.serviceTbt .makeStyles-main-2 {
    background-color: white;
}

.serviceTbt .makeStyles-mainContainer-3 {
    background-color: white;
}

.makeStyles-root-1  {
    background-color: white !important;
}

.service:hover {
    border-left: 3px solid #4CDD84 !important;
    background-color: white;
}