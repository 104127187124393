.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-top: 5px;
}

.txt {
    color: #042315;
    font-size: 16px;
}

.ml {
    margin-left: 5px;
    color:#525252;
}

.ack {
    background-color: #FF832B;
}

.unack {
    background-color: #DA1E28;
}

.cls {
    background-color: #187E3F;
}

.infinite-scroll-component__outerdiv {
    background-color: #fff;
}

.infinite-scroll-component {
    border-radius: 3px;
    box-shadow: 0px 0px 10px #0000001a;
    margin: 0px 2px 10px 2px;
}

.al-ds {
    background-color: white;
    /* padding: 28px 0px 16px 0px; */
    margin-left: 15px;
    width: 100% !important;
}

.makeStyles-cardContainer-87,
.makeStyles-cardContainer-118 {
    align-items: flex-start !important;
}

.makeStyles-cardContainer-8 {
    align-items: flex-start !important;
}

.makeStyles-card-6,
.makeStyles-card-116,
.makeStyles-card-117 {
    width: 95% !important;
}

.makeStyles-card-85 {
    width: 100% !important;
}

.bt {
    width: 140px;
    height: 40px;
    text-transform: capitalize;
    border-radius: 0;
}

.act:hover {
    background-color: #4CDD84 !important;
    /* outline: 2px solid #4CDD84; */
}

.rjt:hover {
    /* outline: 2px solid #29707A; */
    background-color: #29707A;
}

.est:hover {
    background-color: #3D3C3C;
    /* outline: 2px solid #3D3C3C; */
}

.act {
    background-color: #4CDD84;
    color: white;
    margin-right: 2px;
    font-size: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    align-items: flex-start;
    justify-content: left;
}

.rjt {
    background-color: #29707A;
    color: white;
    margin-right: 2px;
    font-size: 14px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    align-items: flex-start;
    justify-content: left;
}

.est {
    background-color: #3D3C3C;
    color: white;
}

.flx {
    display: flex;
    width: 100%;
    color: #042315;
    font-size: 24px;
}

.rt {
    width: 20%;
}

.time {
    color: #3D3C3C;
    font-size: 16px;
}

.tt-no {
    font-size: 14px;
    color: #3D3C3C;
    padding-bottom: 15px;
    margin-top: 20px;
}

.type {
    margin-top: 5px;
}

.summary {
    width: 80%;
    font-size: 20px;
    color: #161616;
}

.status {
    width: 20%;
}

.d-txt {
    color: #042315;
    font-size: 16px;
}

.ty-txt {
    padding: 4px 10px;
    border-radius: 15px;
    background-color: #f2f1ee;
    font-size: 12px;
    color: #161616;
}

.cd {
    box-shadow: 0px 0px 10px #00000012;
    margin-top: 32px;
    padding: 30px 15px 15px 30px;
}

.ep-cd {
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 10%);
    transition: 0.3s;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    margin-top: 32px;

}

.details {
    margin-top: 0px;
}

.ds-txt {
    color: #042315;
    font-size: 16px;
    font-weight: 500;
}

.dls {
    padding: 10px 15px 10px 10px;
}

.para {
    color: #3D3C3C;
    font-size: 14px;
}

.ic {
    fill: #042315;
    background-color: transparent !important;
}
.ic:hover {
    fill: #042315;
    background-color: transparent !important;
}
.backIc{
    background-color: transparent !important;
}
.backIc:hover{
    background-color: transparent !important;
}

.reset {
    font-family: 'IBM Plex Sans', sans-serif;
    box-shadow: none;
    transform: none;
    background-color: #29707A;
    color: #FFFFFF;
    margin-right: 2px;
    font-size: 14px;
    font-weight: 500;
    align-items: flex-start;
    justify-content: left;
}
    
.reset:hover {
    box-shadow: none;
    transform: none;
    background-color: #29707A;
}
    
.done {
    font-family: 'IBM Plex Sans', sans-serif;
    box-shadow: none;
    transform: none;
    background-color: #4CDD84;
    color: #042315;
    margin-right: 2px;
    font-size: 14px;
    font-weight: 500;
    align-items: flex-start;
    justify-content: left;
}
    
.done:hover {
    box-shadow: none;
    transform: none;
    background-color: #4CDD84;
}