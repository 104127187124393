html,
body {
  background-color: white;
  height: 100%;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif !important;
}

div,
span,
svg, p {
  font-family: 'IBM Plex Sans', sans-serif !important;
}

#app {
  height: 100%;
}

a {
  text-decoration: none;
  color: #3D3C3C;
}

a:hover {
  text-decoration: underline;
}

/* react-big-calendar */
.rbc-time-content .rbc-current-time-indicator {
  background-color: #343434;
}

.rbc-time-content .rbc-current-time-indicator::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  left: -6px;
  top: -4.5px;
  border-radius: 5px;
  background-color: #343434;
  position: absolute;
}

.makeStyles-search-12{
  padding-left: 0px !important;
}

.MuiTableCell-sizeMedium {
  padding: 0px 28px 0 0;
  font-family: 'IBM Plex Sans', sans-serif;
  border-bottom: none !important;
}

.MuiLink-root-MuiTableRow-root {
  text-decoration: transparent !important;
}

.MuiLink-root{
  color: #29707A !important;
  text-decoration-color: #29707A !important;
  font-weight: normal !important;

}
.MuiTableRow-root {
  text-decoration: transparent !important;
}

.MuiOutlinedInput-root {
  border-radius: 0px;
}

:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ff462d !important;
  border-color: #ff462d !important;
  border-width: 1px !important;
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  /* color: #042315; */
  color: #000000;
  opacity: 0.6;
}

.MuiTab-textColorPrimary {
  text-transform: capitalize;
  font-size: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #707070;
  border-bottom: 2px solid #e0e0e0;
}

.MuiTableCell-head {
  padding: 16px;
}

.MuiTableCell-body {
  padding: 16px;
}

.Mui-checked + .MuiSwitch-track {
  opacity: 1 !important;
  background-color: #41BD64 !important;
}

.MuiSwitch-thumb {
  background-color: white;
}

.MuiStepIcon-root {
  color: #F2F1EE;
  border: white solid 1px;
}

.MuiStepIcon-root.Mui-active {
  border: #41BD64 solid 1px;
  border-radius: 50%;
  color: transparent;
} 

.MuiStepIcon-root.Mui-completed {
  color: white;
  background: #4CDD84;
  border: 1px solid #4CDD84;
  border-radius: 50%;
}

.MuiStepIcon-text {
  fill: #042315;
  font-size: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
    box-shadow: none !important;
}

.MuiAlert-standardWarning {
  float: left;
  width: 40%;
  position: absolute;
  margin-top: 80px;
  margin-left: 4px;
  color: #042315;
  border: 1px solid #042315;
  box-shadow: none;
}

.MuiChip-root {
  background: #ffffff;
  border: 1px solid #e0e0e0;
}

.MuiChip-root:hover {
  background: #ffffff;
}

p.MuiTypography-root.MuiTypography-body1.MuiTypography-root {
  color: #8F8F8F;
  font: normal normal normal 14px/18px 'IBM Plex Sans', sans-serif;
}

.MuiCardHeader-title {
  color: #161616;
  font: normal normal 500 20px/32px 'IBM Plex Sans', sans-serif;
}

.MuiListItemText-primary {
  font: normal normal 500 14px/18px 'IBM Plex Sans', sans-serif;
  color: #3D3C3C;
}

.MuiListItemText-secondary {
  font: normal normal normal 14px/18px 'IBM Plex Sans', sans-serif;
  color: #525252;
}

.MuiChip-label {
  font: normal normal normal 12px/15px 'IBM Plex Sans', sans-serif;
  color: #3D3C3C;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  color: #042315;
}

.MuiButtonBase-root.MuiIconButton-root:hover {
  background-color: #ffffff;
}

.MuiTouchRipple-root {
  display: none;
}

.MuiAvatar-root {
  background-color: #ffffff;
}

.MuiPaper-root{
  background-color: #ffffff;
}

.MuiCard-root{
  background-color: #ffffff;
}

.MuiCircularProgress-indeterminate {
  color: #4cdd84;
}

.MuiIconButton-root {
  color: #3D3C3C;
}

.MuiMenuItem-root {
  font: normal normal 500 14px/18px 'IBM Plex Sans', sans-serif;
  color: #3D3C3C;
}

.MuiMenuItem-root:hover {
  background-color: #ffffff;
}

p.MuiTypography-root.MuiTypography-subtitle1.MuiTypography-root {
  color: #3D3C3C;
  font: normal normal 500 14px/18px 'IBM Plex Sans', sans-serif;
}

span.MuiTypography-root.MuiTypography-caption.MuiTypography-root {
  color: #3D3C3C;
  font: normal normal 500 14px/18px 'IBM Plex Sans', sans-serif;
}

.MuiDataGrid-root {
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 3px;
  border: none;
}

.MuiListItem-root.Mui-selected {
  background-color: #ffffff;
  border-left: 5px solid #4cdd84;
}

.MuiInputBase-root.MuiInput-root::before {
  border-bottom: 1px solid #042315;
}

.MuiInputBase-root.MuiInput-root::after {
  border-bottom: 2px solid #ff462d;
}

.MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled)::before {
  border-bottom: 1px solid #042315;
}