.ro-ds {
    background-color: white;
    /* padding: 28px 16px 16px 30px; */
    padding: 16px 8px 16px 16px;
    width: 100%;
}

.ro-ib:hover {
    background: transparent !important;
}

.ro-ic {
    fill: #042315;
}

.but {
    width: 140px !important;
    height: 46px !important;
    text-transform: capitalize;
    border-radius: 0;
}

.onCallNotification {
    width: 220px;
    height: 46px;
    text-transform: capitalize;
    border-radius: 0;
}

.del {
    background-color: #29707A;
    color: white;
    margin-right: 2px;
    font-size: 16px !important;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    align-items: flex-start;
    justify-content: left;
}

.del:hover {
    /* outline: 2px solid #29707A; */
    background-color: #29707A;
}

.edi {
    background-color: #4CDD84 !important;
    color: #042315 !important;
    font-size: 16px !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-weight: 500 !important;
    align-items: flex-start !important;
    justify-content: left !important;
}

.edi:hover {
    background-color: #4CDD84 !important;
    /* outline: 2px solid #4CDD84 !important; */
}

.add {
    background-color: #4CDD84 !important;
    color: #042315 !important;
    font-size: 16px !important;
    font-family: 'IBM Plex Sans', sans-serif !important;
    font-weight: 500 !important;
    position: absolute;
    right: 0px;
    top: 80px;
}

.add:hover {
    background-color: #4CDD84 !important;
    /* outline: 2px solid #4CDD84 !important; */
}

.rotationTbt .rotationHeader{
    font: normal normal normal 20px/32px 'IBM Plex Sans', sans-serif;;
    letter-spacing: 0px;
    color: #042315;
    margin-top: 0px;
    /* margin-left: 2px; */
}

.rotationTbt .makeStyles-main-2{
    background-color: white;
}

.rotationTbt .makeStyles-mainContainer-3{
    background-color: white;
}

.makeStyles-root-1  {
    background-color: white !important;
}

.rotation:hover {
    border-left: 3px solid #4CDD84 !important;
    background-color: white;
}

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
    box-shadow: none !important;
} */